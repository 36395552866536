<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template>
  <q-item :to="`/blog/${item.document.slug}`">
    <q-item-section avatar>
      <q-avatar
        size="50"
        square
      >
        <q-img
          :src="item.document.thumbnailUrl ?? '/placeholder.svg'"
          :placeholder-src="item.document.thumbnailPlaceholder"
          fit="contain"
          spinner-size="sm"
          spinner-color="primary"
        />
      </q-avatar>
    </q-item-section>
    <q-item-section>
      <q-item-label
        class="text-subtitle2"
        v-html="item.highlight?.title?.value ?? item.document.title"
      />
      <q-item-label caption>
        <span>
          Published
          {{ format(item.document.publishedAt * 1000, 'MMM D, YYYY h:mm:ss A') }}
        </span>
      </q-item-label>
    </q-item-section>
    <q-item-section side>
      <q-chip
        class="no-border-radius"
        outline
        square
      >
        Blog
      </q-chip>
    </q-item-section>
  </q-item>
</template>

<script lang="ts" setup>
import type { Article } from '~/types/search';
import type { SearchResponseHit } from '~/types/typesense';

const { format } = useDateFormat();

defineProps({
  item: {
    required: true,
    type: Object as PropType<SearchResponseHit<Article>>,
  },
});
</script>
